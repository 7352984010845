import React, { useEffect, useState } from "react";
import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import ContainerContent from "./ContainerContent";
import Button from "../../Common/FormElements/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { closeRightModal } from "../../../REDUX/actions/rightModal";
import { formLabelsData } from "../../../helpers/fakeData/Commandes";
import { createApprov } from "../../../REDUX/actions/approvisionnement";

const AddContainer = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("Infos générales");
  const [disabled, setDisabled] = useState(true);
  const [transportExceeded, setTransportExceeded] = useState(false);

  const { approvLoading } = useSelector((state) => state.approvReducer);

  const [forms, setForms] = useState({
    container: {},
    packingList: [],
    payments: [],
    attachments: [],
  });
  const checkFormValidation = () => {
    let updatedInvalidFields = [];
    for (const formRow of formLabelsData) {
      for (const field of formRow) {
        const { name, type, required } = field;
        if (required) {
          if (type === "select") {
            if (!forms.container[name]?.code) {
              updatedInvalidFields.push(name);
            }
          } else if (
            forms.container[name] === undefined ||
            forms.container[name] === null ||
            !String(forms.container[name])?.trim()?.length > 0
          ) {
            updatedInvalidFields.push(name);
          }
        }
      }
    }
    if (!forms.packingList.length) {
      updatedInvalidFields.push("packingList");
    }
    if (updatedInvalidFields?.length > 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handleSubmit = () => {
    dispatch(createApprov({ ...forms }));
  };

  useEffect(() => {
    checkFormValidation();
  }, [forms?.container, forms?.packingList]);

  console.log({ forms });

  return (
    <div className="modifyOrder d-flex flex-column justify-content-between h100">
      <div>
        <h2 className="sBold f25 quickSand text-blue px-5 mb-5">
          Ajouter un container
        </h2>
        <TabsNavbar
          tabs={["Infos générales", "Packing list"]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          className="gap-5"
          disabledIndex={2}
        />
        <ContainerContent
          activeTab={activeTab}
          forms={forms}
          setForms={setForms}
          setTransportExceeded={setTransportExceeded}
        />
        {transportExceeded && (
          <p className="f14 text-red text-center mb-0">
            Vous avez dépassé le prix de livraison total, veuillez supprimer un
            modèle de la liste "Packing List" !
          </p>
        )}
        <div
          className={`buttonsContainer d-flex justify-content-center mt-${
            transportExceeded ? "3" : "5"
          }`}
        >
          <Button
            variant="outline"
            className="me-4"
            onClick={() => dispatch(closeRightModal())}
          >
            Annuler
          </Button>
          <Button
            disabled={disabled || transportExceeded}
            onClick={handleSubmit}
            loading={approvLoading}
          >
            Enregistrer
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddContainer;
