import React, { useEffect, useState } from "react";
import ContainerInfosTabContent from "./ContainerInfosTabContent";
import PackingListTabContent from "./PackingListTabContent";
import ContainerCommentaireTabContent from "./ContainerCommentaireTabContent";
import Paiements from "../ModifyOrder/Paiements";
import { useDispatch, useSelector } from "react-redux";
import { getModels } from "../../../REDUX/actions/model";
import { fetchEntrepots } from "../../../REDUX/actions/entrepot";

function ContainerContent({
  update,
  activeTab,
  forms,
  setForms,
  setTransportExceeded,
}) {
  const dispatch = useDispatch();
  const { approv } = useSelector((state) => state.approvReducer);
  const { entrepots } = useSelector((state) => state.entrepotReducer);

  const { informations } = useSelector((state) => state.modelReducer);
  const [totalHT, setTotalHT] = useState(0);
  const [totalTTC, setTotalTTC] = useState(0);
  const [TVA, setTVA] = useState(0);

  const handleFormsChange = (name, formData) => {
    // console.log({ name, formData });
    setForms((currentForms) => {
      return { ...currentForms, [name]: formData };
    });
  };

  useEffect(() => {
    dispatch(getModels());
  }, [dispatch]);

  useEffect(() => {
    const packingList = approv?.packingList;
    let hasUpdated = false;
    if (packingList?.length > 0) {
      hasUpdated =
        !forms?.packingList.every(
          (packing, index) => +packing?.id === +packingList[index]?.id
        ) || forms?.packingList?.length !== packingList?.length;
    }
    if (hasUpdated || !packingList?.length > 0) {
      const totalHT = forms?.packingList?.reduce((acc, item) => {
        const currModel = informations?.find(
          (model) => +model?.id === +item?.model?.code
        );
        if (!isNaN(currModel?.attributes?.retailerPriceHT)) {
          return acc + currModel?.attributes?.retailerPriceHT;
        }
        return acc;
      }, 0);
      const totalTTC = forms?.packingList?.reduce((acc, item) => {
        const currModel = informations?.find(
          (model) => +model?.id === +item?.model?.code
        );
        if (!isNaN(currModel?.attributes?.publicPriceTTC)) {
          return acc + currModel?.attributes?.publicPriceTTC;
        }
        return acc;
      }, 0);
      setTotalHT(totalHT);
      setTotalTTC(totalTTC);
      setTVA(((totalTTC - totalHT) / totalHT) * 100);
    } else if (update || forms?.packingList?.length === packingList?.length) {
      setTotalHT(approv?.amountHT ?? 0);
      setTotalTTC(approv?.amountTTC ?? 0);
      setTVA(approv?.TVA ?? 0);
    }
  }, [
    forms?.packingList,
    forms?.packingList?.length,
    informations,
    setForms,
    approv?.packingList,
    approv,
    update,
  ]);

  useEffect(() => {
    dispatch(fetchEntrepots());
  }, []);

  // console.log({ entrepots });

  return (
    <>
      <div
        className={`${activeTab === "Infos générales" ? "d-block" : "d-none"}`}
      >
        <ContainerInfosTabContent
          handleFormsChange={handleFormsChange}
          initialValues={forms}
          totalHT={totalHT}
          totalTTC={totalTTC}
          TVA={TVA}
          totalCost={
            forms?.packingList?.reduce((acc, curr) => {
              if (curr?.hide) return acc;
              return (
                acc +
                parseFloat(
                  !isNaN(curr?.deliveredCost)
                    ? curr?.deliveredCost * (curr?.quantity ?? 1)
                    : 0
                )
              );
            }, 0) + +forms?.container?.deliveryPrice
          }
          entrepots={entrepots}
        />
      </div>
      <div className={`${activeTab === "Packing list" ? "d-block" : "d-none"}`}>
        <PackingListTabContent
          handleFormsChange={handleFormsChange}
          initialValues={forms?.packingList}
          totalTransport={forms?.container?.deliveryPrice}
          taux={forms?.container?.taux}
          setTransportExceeded={setTransportExceeded}
          entrepot={forms?.container?.realEntrepot}
        />
      </div>

      <div className={`${activeTab === "Paiements" ? "d-block" : "d-none"}`}>
        <Paiements formData={forms} setFormData={setForms} />
      </div>
      {activeTab === "Commentaire" && <ContainerCommentaireTabContent />}
    </>
  );
}

export default ContainerContent;
