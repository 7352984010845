import React, { useEffect, useState } from "react";

import { packingListTitles } from "../../../helpers/fakeData/Commandes";
import PackingListItem from "./PackingListItem";
import { v4 as uuidv4 } from "uuid";
import { getPackingSelectValue } from "./getPackingSelectValue";
import { useSelector } from "react-redux";
import _ from "lodash";
import { isDefined } from "../../../Utils";

const PackingListTabContent = ({
  handleFormsChange,
  initialValues,
  totalTransport,
  taux,
  setTransportExceeded,
}) => {
  const { approv } = useSelector((state) => state.approvReducer);
  const [formData, setFormData] = useState(initialValues);

  const handleAddItem = () => {
    setFormData([
      ...formData,
      {
        id: uuidv4(),
        newPacking: true,
      },
    ]);
  };

  const handleItemChange = (id, updatedItemData) => {
    setFormData((formData) =>
      formData.map((item) => (item.id === id ? updatedItemData : item))
    );
  };

  const handleDeleteItem = (id, modelId) => {
    setFormData((currentFormData) => {
      const updatedFormData = [...currentFormData];
      _.remove(updatedFormData, function (item) {
        if (isDefined(modelId) && !item?.isProduct) {
          return item?.model?.code?.toString() === modelId?.toString();
        }
        return item?.id?.toString() === id?.toString();
      });

      return updatedFormData;
    });
  };

  useEffect(() => {
    handleFormsChange("packingList", [...formData]);
    const packingListTransport = formData?.reduce((acc, item) => {
      if (item?.hide) return acc;
      return (item?.transport ?? 0) * (item?.quantity ?? 1) + acc;
    }, 0);
    setTransportExceeded(
      packingListTransport.toFixed(2) > parseFloat(totalTransport)
    );
  }, [formData, totalTransport, setTransportExceeded]);

  useEffect(() => {
    const values = getPackingSelectValue(approv);

    const updatedValuesWithQuantity = values?.map((item, index) => {
      if (item?.isProduct) return item;

      if (
        index >
        values?.findIndex(
          (packing) => packing?.model?.code === item?.model?.code
        )
      ) {
        return { ...item, hide: true, quantity: 1 };
      }

      const quantity = values?.reduce((acc, packing) => {
        if (packing?.model?.code === item?.model?.code) {
          return acc + 1;
        }
        return acc;
      }, 0);

      return {
        ...item,
        quantity,
      };
    });

    setFormData(updatedValuesWithQuantity);
  }, [approv]);

  return (
    <div className="packingListForm modalContent px-5 pt-4">
      <div className="alignH bg-dimGrey ps-2 py-3 justify-content-between w100 gap-2">
        {packingListTitles?.map(({ id, label, width }) => (
          <p className={`${width}  mb-0 sBold text-brightGrey f12`} key={id}>
            {label}
          </p>
        ))}
      </div>
      <div>
        {formData?.map((packingItemData) => {
          return (
            <PackingListItem
              key={packingItemData.id}
              packingItemData={packingItemData}
              totalTransport={totalTransport}
              taux={taux}
              length={formData?.length}
              setFormData={setFormData}
              handleItemChange={handleItemChange}
              handleDeleteItem={handleDeleteItem}
              formData={formData}
            />
          );
        })}
      </div>

      <button
        onClick={handleAddItem}
        className="addAttachment ms-3 mt-3 f12 text-softBlue border-0 bg-transparent underline"
      >
        + Ajouter un element
      </button>
    </div>
  );
};

export default PackingListTabContent;
