import React, { useState } from "react";

import {
  getEntrepotSelectValue,
  getFormEltType,
  isDefined,
} from "../../../Utils";
import Attachements from "../../Common/Forms/Attachements/Attachements";
import { formLabelsData } from "../../../helpers/fakeData/Commandes";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { transformSavedAttachments } from "../../Sav/SavPreview/transformSavedAttachments";
import { initial } from "lodash";

const ContainerInfosTabContent = ({
  handleFormsChange,
  initialValues,
  totalCost,
  totalHT,
  totalTTC,
  TVA,
  entrepots,
}) => {
  const { approv } = useSelector((state) => state.approvReducer);
  const [attachments, setAttachments] = useState([]);
  const [formData, setFormData] = useState(initialValues.container ?? {});

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData((formData) => ({ ...formData, [name]: value }));
  };

  useEffect(() => {
    handleFormsChange("container", formData);
  }, [formData]);

  useEffect(() => {
    handleFormsChange("attachments", attachments);
  }, [attachments]);

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      returnCost: totalCost || "-",
    }));
  }, [totalCost]);

  useEffect(() => {
    const data = {
      containerName: approv?.containerName,
      factory: approv?.factory,
      taux: approv?.taux,
      deliveryPrice: approv?.deliveryPrice,
      QC: approv?.QC ?? 0,
      returnCost: approv?.returnCost,
      amountTTC: approv?.amountTTC,
      TVA:
        approv?.TVA ??
        ((approv?.amountTTC - approv?.amountHT) / approv?.amountHT) * 100,
      amountHT: approv?.amountHT,
      orderDate: approv?.orderDate
        ? moment(approv.orderDate).utc(true).toDate()
        : null,
      estimatedDate: approv?.estimatedDate
        ? moment(approv.estimatedDate).utc(true).toDate()
        : null,
      arrivalDate: approv?.arrivalDate
        ? moment(approv.arrivalDate).utc(true).toDate()
        : null,
      entrepot: approv?.entrepot ?? "",
      realEntrepot: approv?.realEntrepot?.name ?? null,
    };
    setFormData(data);

    setAttachments(transformSavedAttachments(approv?.attachments || []));
    handleFormsChange("container", data);
  }, [approv]);

  // useEffect(() => {
  //   const amountHT = parseFloat(formData?.amountHT || 0);
  //   setFormData((formData) => ({
  //     ...formData,
  //     amountTTC: amountHT + amountHT * (parseFloat(formData?.TVA || 0) / 100),
  //   }));
  // }, [formData?.amountHT]);

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      amountHT: totalHT,
      amountTTC: totalTTC,
      TVA,
      // parseFloat(totalHT || 0) +
      // parseFloat(totalHT || 0) * (formData?.TVA / 100),
    }));
  }, [totalHT, totalTTC, TVA]);

  return (
    <div className="addContainerForm d-flex flex-column gap-3 my-5">
      <div className="d-flex flex-column gap-3 mx-5">
        {formLabelsData?.map((formRow, index) => {
          return (
            <div className="d-flex flex-column" key={index}>
              <div className="alignH gap-4">
                {formRow?.map(
                  (
                    {
                      label,
                      type,
                      surfixText,
                      placeholder,
                      currencyChoice,
                      name,
                      inputType,
                      disabled,
                    },
                    id
                  ) => {
                    let value = formData[name];
                    return (
                      label !== "hr" && (
                        <div className="w100 " key={id}>
                          <div className="w100  d-flex flex-column gap-2">
                            <span className="text-softBlack normal f16">
                              {label}
                            </span>
                            {getFormEltType(
                              label,
                              type,
                              surfixText,
                              placeholder,
                              undefined,
                              undefined,
                              currencyChoice,
                              name,
                              handleChange,
                              inputType,
                              false,
                              `${name}Currency`,
                              disabled,
                              value,
                              undefined,
                              getEntrepotSelectValue(entrepots)
                            )}
                          </div>
                        </div>
                      )
                    );
                  }
                )}
              </div>
              {formRow[3]?.label === "hr" && (
                <hr
                  style={{ borderColor: "#abb8b2" }}
                  className="mt-5"
                  width={"100%"}
                />
              )}
            </div>
          );
        })}
        <hr
          style={{ borderColor: "#abb8b2" }}
          className="mt-4 mb-2"
          width={"100%"}
        />
      </div>
      <Attachements
        attachments={attachments}
        setAttachments={setAttachments}
        acceptTypes={"/*"}
      />
    </div>
  );
};

export default ContainerInfosTabContent;
