import { v4 as uuidv4 } from "uuid";
import productImage from "../../../Images/Images/Commandes/product.png";
import cuve from "../../../Images/Images/Catalaogue/cuve.jpg";
import jupeImage from "../../../Images/Images/Catalaogue/leadGray.jpg";

export const ordersListData = [
  {
    id: 1,
    numOrder: "141147",
    refClient: ["Jean Alfred"],
    montantTTC: "4 250,00",
    model: "Sublime Line",
    orderDate: "11/07/2022",
    desiredDeliveryDate: "20/07/2022",
    deliveryDate: "",
    status: "En attente",
  },
  {
    id: 2,
    numOrder: "141146",
    refClient: ["Jean Alfred", "Victor Hugo", "François-Marie Arouet"],
    montantTTC: "4 250,00",
    model: "Sublime Line",
    orderDate: "11/07/2022",
    desiredDeliveryDate: "20/07/2022",
    deliveryDate: "21/07/2022",
    status: "En attente",
  },
  {
    id: 3,
    numOrder: "141145",
    refClient: ["Jean Alfred"],
    montantTTC: "4 250,00",
    model: "Sublime Line",
    orderDate: "11/07/2022",
    desiredDeliveryDate: "20/07/2022",
    deliveryDate: "",
    status: "En attente",
  },
  {
    id: 4,
    numOrder: "141144",
    refClient: ["Jean Alfred"],
    montantTTC: "4 250,00",
    model: "Sublime Line",
    orderDate: "11/07/2022",
    desiredDeliveryDate: "20/07/2022",
    deliveryDate: "",
    status: "Payée",
  },
  {
    id: 5,
    numOrder: "141143",
    refClient: ["Jean Alfred"],
    montantTTC: "4 250,00",
    model: "Sublime Line",
    orderDate: "11/07/2022",
    desiredDeliveryDate: "20/07/2022",
    deliveryDate: "",
    status: "Acompte",
  },
  {
    id: 6,
    numOrder: "141142",
    refClient: ["Jean Alfred"],
    montantTTC: "4 250,00",
    model: "Sublime Line",
    orderDate: "11/07/2022",
    desiredDeliveryDate: "20/07/2022",
    deliveryDate: "21/07/2022",
    status: "Livrée",
  },
  {
    id: 7,
    numOrder: "141141",
    refClient: ["Jean Alfred"],
    montantTTC: "4 250,00",
    model: "Sublime Line",
    orderDate: "11/07/2022",
    desiredDeliveryDate: "20/07/2022",
    deliveryDate: "",
    status: "En attente",
  },
  {
    id: 8,
    numOrder: "141140",
    refClient: ["Jean Alfred"],
    montantTTC: "4 250,00",
    model: "Sublime Line",
    orderDate: "11/07/2022",
    desiredDeliveryDate: "20/07/2022",
    deliveryDate: "",
    status: "Soldée",
  },
];
export const ordersListDataAdmin = [
  {
    id: uuidv4(),
    numOrder: "141147",
    numFact: "141147",
    company: "ADream38",
    aa: "SY",
    commission: "338,00",
    margeNet: "850,00",
    txMarge: "20",
    montantHT: "3 850,00",
    TVA: "850,00",
    montantTTC: "4 250,00",
    paiement: "paid",
  },
  {
    id: uuidv4(),
    numOrder: "141147",
    numFact: "141147",
    company: "ADream38",
    aa: "SY",
    commission: "338,00",
    margeNet: "850,00",
    txMarge: "20",
    montantHT: "3 850,00",
    TVA: "850,00",
    montantTTC: "4 250,00",
    paiement: "unpaid",
  },
  {
    id: uuidv4(),
    numOrder: "141147",
    numFact: "141147",
    company: "ADream38",
    aa: "SY",
    commission: "338,00",
    margeNet: "850,00",
    txMarge: "20",
    montantHT: "3 850,00",
    TVA: "850,00",
    montantTTC: "4 250,00",
    paiement: "unpaid",
  },
  {
    id: uuidv4(),
    numOrder: "141147",
    numFact: "141147",
    company: "ADream38",
    aa: "SY",
    commission: "338,00",
    margeNet: "850,00",
    txMarge: "20",
    montantHT: "3 850,00",
    TVA: "850,00",
    montantTTC: "4 250,00",
    paiement: "paid",
  },
  {
    id: uuidv4(),
    numOrder: "141147",
    numFact: "141147",
    company: "ADream38",
    aa: "SY",
    commission: "338,00",
    margeNet: "850,00",
    txMarge: "20",
    montantHT: "3 850,00",
    TVA: "850,00",
    montantTTC: "4 250,00",
    paiement: "paid",
  },
  {
    id: uuidv4(),
    numOrder: "141147",
    numFact: "141147",
    company: "ADream38",
    aa: "SY",
    commission: "338,00",
    margeNet: "850,00",
    txMarge: "20",
    montantHT: "3 850,00",
    TVA: "850,00",
    montantTTC: "4 250,00",
    paiement: "unpaid",
  },
  {
    id: uuidv4(),
    numOrder: "141147",
    numFact: "141147",
    company: "ADream38",
    aa: "SY",
    commission: "338,00",
    margeNet: "850,00",
    txMarge: "20",
    montantHT: "3 850,00",
    TVA: "850,00",
    montantTTC: "4 250,00",
    paiement: "paid",
  },
  {
    id: uuidv4(),
    numOrder: "141147",
    numFact: "141147",
    company: "ADream38",
    aa: "SY",
    commission: "338,00",
    margeNet: "850,00",
    txMarge: "20",
    montantHT: "3 850,00",
    TVA: "850,00",
    montantTTC: "4 250,00",
    paiement: "unpaid",
  },
];
export const orderProductsData = [
  {
    id: uuidv4(),
    name: "SPA",
    category: "SPA",
    qty: 2,
    HT: "4 550,00",
    TVA: "4 550,00",
    TTC: "4 550,00",
  },
  {
    id: uuidv4(),
    name: "SPA",
    category: "SPA",
    qty: 2,
    HT: "4 550,00",
    TVA: "4 550,00",
    TTC: "4 550,00",
  },
  {
    id: uuidv4(),
    name: "SPA",
    category: "SPA",
    qty: 2,
    HT: "4 550,00",
    TVA: "4 550,00",
    TTC: "4 550,00",
  },
  {
    id: uuidv4(),
    name: "SPA",
    category: "SPA",
    qty: 2,
    HT: "4 550,00",
    TVA: "4 550,00",
    TTC: "4 550,00",
  },
];
export const orderPaymentsData = [
  { id: uuidv4(), paymentNumber: 1, amount: "2 850,00" },
  { id: uuidv4(), paymentNumber: 2, amount: "2 850,00" },
  { id: uuidv4(), paymentNumber: 3, amount: "2 850,00" },
];
export const orderSummaryData = [
  {
    id: uuidv4(),
    dimensions: "150x250x51",
    weight: 85,
    serialNumber: "154478411285",
  },
  {
    id: uuidv4(),
    dimensions: "150x250x51",
    weight: 85,
    serialNumber: "154478411285",
  },
  {
    id: uuidv4(),
    dimensions: "150x250x51",
    weight: 85,
    serialNumber: "154478411285",
  },
  {
    id: uuidv4(),
    dimensions: "150x250x51",
    weight: 85,
    serialNumber: "154478411285",
  },
  {
    id: uuidv4(),
    dimensions: "150x250x51",
    weight: 85,
    serialNumber: "154478411285",
  },
];

export const approvFakeData = [
  {
    id: 1,
    numContainer: "PO21",
    usine: "HALO",
    orderDate: "11/07/2022",
    estimDate: "11/07/2022",
    arrivalDate: "11/07/2022",
    TX: "0,92",
    QC: "300 $",
    keys: 50,
    containerCost: "4 500,00",
  },
  {
    id: 2,
    numContainer: "PO21",
    usine: "HALO",
    orderDate: "11/07/2022",
    estimDate: "11/07/2022",
    arrivalDate: "11/07/2022",
    TX: "0,92",
    QC: "300 $",
    keys: 50,
    containerCost: "4 500,00",
  },
  {
    id: 3,
    numContainer: "PO21",
    usine: "HALO",
    orderDate: "11/07/2022",
    estimDate: "11/07/2022",
    arrivalDate: "11/07/2022",
    TX: "0,92",
    QC: "300 $",
    keys: 50,
    containerCost: "4 500,00",
  },
  {
    id: 4,
    numContainer: "PO21",
    usine: "HALO",
    orderDate: "11/07/2022",
    estimDate: "11/07/2022",
    arrivalDate: "11/07/2022",
    TX: "0,92",
    QC: "300 $",
    keys: 50,
    containerCost: "4 500,00",
  },
  {
    id: 5,
    numContainer: "PO21",
    usine: "HALO",
    orderDate: "11/07/2022",
    estimDate: "11/07/2022",
    arrivalDate: "11/07/2022",
    TX: "0,92",
    QC: "300 $",
    keys: 50,
    containerCost: "4 500,00",
  },
  {
    id: 6,
    numContainer: "PO21",
    usine: "HALO",
    orderDate: "11/07/2022",
    estimDate: "11/07/2022",
    arrivalDate: "11/07/2022",
    TX: "0,92",
    QC: "300 $",
    keys: 50,
    containerCost: "4 500,00",
  },
  {
    id: 7,
    numContainer: "PO21",
    usine: "HALO",
    orderDate: "11/07/2022",
    estimDate: "11/07/2022",
    arrivalDate: "11/07/2022",
    TX: "0,92",
    QC: "300 $",
    keys: 50,
    containerCost: "4 500,00",
  },
];

export const formLabelsData = [
  [
    {
      label: "Nom du container",
      type: "input",
      placeholder: "Nom du container",
      name: "containerName",
      required: true,
    },

    { label: "Usine", type: "input", name: "factory", required: true },
    {
      label: "Prix de livraison",
      type: "input",
      inputType: "number",
      placeholder: "Prix",
      name: "deliveryPrice",
      required: true,
    },
  ],
  [
    {
      label: "Date de commande",
      type: "calendarInput",
      placeholder: "Date de commande",
      name: "orderDate",
      required: true,
    },
    {
      label: "Date estimée",
      type: "calendarInput",
      placeholder: "Date estimée",
      name: "estimatedDate",
    },
    {
      label: "Date arrivée",
      type: "calendarInput",
      placeholder: "Date arrivée",
      name: "arrivalDate",
    },
  ],
  [
    {
      label: "Taux €/$",
      type: "input",
      placeholder: "Taux",
      name: "taux",
      inputType: "number",
      required: true,
    },
    // {
    //   label: "QC",
    //   type: "input",
    //   placeholder: "Produit",
    //   surfixText: "$",
    //   name: "QC",
    // },
    {
      label: "Coût Rendu",
      type: "input",
      placeholder: "Produit",
      surfixText: "€",
      name: "returnCost",
      disabled: true,
      required: true,
    },
    {
      label: "Entrepôt",
      type: "selectEntrepot",
      placeholder: "Entrepôt",
      name: "realEntrepot",
      disabled: false,
      required: false,
    },
    { label: "hr" },
  ],
  [
    {
      label: "Montant TTC",
      type: "input",
      currencyChoice: false,
      name: "amountTTC",
      inputType: "number",
      disabled: false,
      required: true,
    },
    {
      label: "TVA %",
      type: "input",
      currencyChoice: false,
      name: "TVA",
      inputType: "number",
      required: true,
      disabled: false,
    },
    {
      label: "Montant HT",
      type: "input",
      currencyChoice: false,
      name: "amountHT",
      inputType: "number",
      required: true,
      disabled: false,
    },
  ],
  // [
  //   {
  //     label: "Paiement 1",
  //     type: "input",
  //     placeholder: "Paiement",
  //     currencyChoice: true,
  //     inputType: "number",
  //     disabled: true,
  //   },
  //   {
  //     label: "Paiement 2",
  //     type: "input",
  //     placeholder: "Paiement",
  //     currencyChoice: true,
  //     inputType: "number",
  //     disabled: true,
  //   },
  //   {
  //     label: "Paiement 3",
  //     type: "input",
  //     placeholder: "Paiement",
  //     currencyChoice: true,
  //     inputType: "number",
  //     disabled: true,
  //   },
  // ],
];

export const packingListTitles = [
  { id: uuidv4(), label: "Modèle", width: "w10" },
  { id: uuidv4(), label: "Référence", width: "w10" },
  { id: uuidv4(), label: "Quantité", width: "w5" },
  { id: uuidv4(), label: "Cuve", width: "w10" },
  { id: uuidv4(), label: "Panneau", width: "w10" },
  { id: uuidv4(), label: "N° série", width: "w10" },
  { id: uuidv4(), label: "Prix d'achat", width: "w10" },
  { id: uuidv4(), label: "QC", width: "w5" },
  { id: uuidv4(), label: "Coût achat", width: "w10" },
  { id: uuidv4(), label: "Transport", width: "w5" },
  { id: uuidv4(), label: "Coût rendu", width: "w10" },
  { id: uuidv4(), label: "", width: "w5" },
];

export const fakeTabLength = [1, 2, 3, 4, 5];

export const packingListFakeValues = [
  { type: "select", placeholder: "Orion ML", width: "w10" },
  { type: "select", placeholder: "HL3001 ML", width: "w10" },
  { type: "select", placeholder: "beige", width: "w5", icon: true },
  { type: "select", placeholder: "brown", width: "w5", icon: true },
  { type: "input", placeholder: "3001344565", width: "w10" },
  { type: "input", placeholder: "3 500,00", surfixText: "€", width: "w10" },
  { type: "input", placeholder: "2 800,00", surfixText: "€", width: "w10" },
  { type: "input", placeholder: "3", width: "w5" },
  { type: "input", placeholder: "3250,00", surfixText: "€", width: "w10" },
  { type: "input", placeholder: "3 100,00", surfixText: "€", width: "w10" },
];
export const orderDetailsValues = [
  {
    id: 1,
    img: productImage,
    productName: "Spa 3 places Pégase",
    statut: "En stock",
    refClient: "Dupont Jacques",
    qute: 1,
    accessoires: 5,
    delivery: "21/07/2022",
    price: 11890,
    cuve: cuve,
    jupeImage: jupeImage,
  },
  {
    id: 2,
    img: productImage,
    productName: "Spa 3 places Pégase",
    statut: "En stock",
    refClient: "Dupont Jacques",
    qute: 1,
    accessoires: 5,
    delivery: "21/07/2022",
    price: 11890,
    cuve: cuve,
    jupeImage: jupeImage,
  },
];
export const approvDetailsValues = [
  {
    model: "Orion ML",
    reference: "HL3001 ML",
    cuve: cuve,
    panel: jupeImage,
    serialNumber: "3001344565",
    buyingPrice: "3 500",
    fullCost: "23",
    buyingCost: "2 800",
    keys: "1",
    transport: "250",
    profit: "11 890",
  },
  {
    model: "Orion ML",
    reference: "HL3001 ML",
    cuve: cuve,
    panel: jupeImage,
    serialNumber: "3001344565",
    buyingPrice: "3 500",
    fullCost: "23",
    buyingCost: "2 800",
    keys: "1",
    transport: "250",
    profit: "11 890",
  },
  {
    model: "Orion ML",
    reference: "HL3001 ML",
    cuve: cuve,
    panel: jupeImage,
    serialNumber: "3001344565",
    buyingPrice: "3 500",
    fullCost: "23",
    buyingCost: "2 800",
    keys: "1",
    transport: "250",
    profit: "11 890",
  },
  {
    model: "Orion ML",
    reference: "HL3001 ML",
    cuve: cuve,
    panel: jupeImage,
    serialNumber: "3001344565",
    buyingPrice: "3 500",
    fullCost: "23",
    buyingCost: "2 800",
    keys: "1",
    transport: "250",
    profit: "11 890",
  },
];
