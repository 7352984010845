import { toast } from "react-toastify";
import NEW_API from "../../Api/NewApi";
import { refetchCommandeItem } from "./commandes";

export const getCatalogue =
  (activeTab, productsactiveTab, searchInput) => async (dispatch) => {
    try {
      const query =
        activeTab === "Produits"
          ? `type=1&gamme=${productsactiveTab}`
          : `type=${activeTab}`;
      const searchquery = searchInput ? `search=${searchInput}` : "";
      dispatch({ type: "CATALOGUE_LOADING" });
      const { data } = await NEW_API.get(
        `/api/fetchcatalogue?${query}&${searchquery}`
      );
      dispatch({ type: "CATALOGUE_SUCCESS", payload: data.data.catalogue });

      dispatch({ type: "CART_SUCCESS", payload: data.data.carts });
    } catch (error) {
      dispatch({ type: "CATALOGUE_ERROR" });
    }
  };

export const switchCarts = (cartId) => async (dispatch) => {
  try {
    localStorage.setItem("selectedCart", cartId);
    dispatch({ type: "SWITCH_CARTS", payload: cartId });
  } catch (error) {
    console.log(error);
    dispatch({ type: "CATALOGUE_ERROR" });
  }
};

export const createCart =
  ({ activeTab, searchInput, productsactiveTab }) =>
  async (dispatch) => {
    try {
      console.log({ activeTab, searchInput, productsactiveTab });
      dispatch({ type: "NEW_CART_LOADING" });

      await NEW_API.post(`/api/createCart`);
      dispatch(getCatalogue(activeTab, productsactiveTab, searchInput));
    } catch (error) {
      dispatch({ type: "CART_ERROR" });
    }
  };

export const addToCart =
  (packingId, qte, { activeTab, searchInput, productsactiveTab }) =>
  async (dispatch, getState) => {
    try {
      const { cart } = getState().catalogueReducer;
      dispatch({ type: "CART_LOADING", payload: packingId });
      if (qte === 1 && qte) {
        await NEW_API.post(`/api/addToCart`, {
          data: { packingId, commandeId: cart.id },
        });
      } else {
        await NEW_API.post(`/api/addToCartMultiple`, {
          data: { packingId, qte, commandeId: cart.id },
        });
      }

      dispatch(getCatalogue(activeTab, productsactiveTab, searchInput));
    } catch (error) {
      dispatch({ type: "CART_ERROR" });
    }
  };

export const removeFromCart =
  (packingId, { activeTab, searchInput, productsactiveTab }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "CART_LOADING", payload: packingId });
      await NEW_API.put(`/api/packings/${packingId}`, {
        data: { sold: false, commande: null, discount: 0 },
      });
      dispatch(getCatalogue(activeTab, productsactiveTab, searchInput));
    } catch (error) {
      dispatch({ type: "CART_ERROR" });
    }
  };

export const updatePacking =
  (packingIds, data, { activeTab, searchInput, productsactiveTab }) =>
  async (dispatch) => {
    try {
      for (let i = 0; i < packingIds.length; i++) {
        const packingId = packingIds[i];

        dispatch({ type: "CART_LOADING", payload: packingId });
        await NEW_API.put(`/api/packings/${packingId}`, {
          data: data,
        });
      }
      dispatch(getCatalogue(activeTab, productsactiveTab, searchInput));
    } catch (error) {
      dispatch({ type: "CART_ERROR" });
    }
  };
export const updateCart = (cartId, data) => async (dispatch) => {
  try {
    dispatch({ type: "CART_LOADING", payload: cartId });
    await NEW_API.put(`/api/commandes/${cartId}`, {
      data: { ...data },
    });

    dispatch(refetchCart());
  } catch (error) {
    dispatch({ type: "CART_ERROR" });
  }
};

export const refetchCart = () => async (dispatch, getState) => {
  try {
    const { cart } = getState().catalogueReducer;
    const { data } = await NEW_API.get(
      `/api/refetchCart?commandeId=${cart.id}`
    );

    dispatch({ type: "REFETCH_CART", payload: data.data.cart });
  } catch (error) {
    dispatch({ type: "CART_ERROR" });
  }
};

export const generateInvoice =
  (commandeId, refetchCommande = false) =>
  async (dispatch) => {
    try {
      if (refetchCommande) {
        dispatch({ type: "GENERATE_FACTURE_LOADING", payload: commandeId });
      } else {
        dispatch({ type: "CART_LOADING", payload: 1 });
      }
      await NEW_API.post(`/api/generatefacture`, {
        data: { commandeId: commandeId },
      });
      if (refetchCommande) {
        dispatch(refetchCommandeItem(commandeId));
      } else {
        dispatch(refetchCart(commandeId));
      }
      toast.success("Facture générée avec succès");
    } catch (error) {
      dispatch({ type: "CART_ERROR" });
    }
  };

export const generateBonDeCommande =
  (commandeId, refetchCommande = false) =>
  async (dispatch) => {
    try {
      // console.log({ commandeId, refetchCommande });

      if (refetchCommande) {
        dispatch({ type: "GENERATE_BON_LOADING", payload: commandeId });
      } else {
        dispatch({ type: "CART_LOADING", payload: 1 });
      }
      await NEW_API.post(`/api/generateBonDeCommande`, {
        data: { commandeId: commandeId },
      });
      if (refetchCommande) {
        dispatch(refetchCommandeItem(commandeId));
      } else {
        dispatch(refetchCart(commandeId));
      }
      toast.success("Bon de commande généré avec succès");
    } catch (error) {
      dispatch({ type: "CART_ERROR" });
    }
  };

export const updateFacture =
  (factureId, data, commandeId) => async (dispatch) => {
    try {
      await NEW_API.put(`/api/factures/${factureId}`, {
        data: data,
      });

      dispatch(generateInvoice(commandeId));
    } catch (error) {
      dispatch({ type: "CART_ERROR" });
    }
  };

export const updateBonDeCommande =
  (bonId, data, commandeId) => async (dispatch) => {
    try {
      await NEW_API.put(`/api/bon-de-commandes/${bonId}`, {
        data: data,
      });

      dispatch(generateBonDeCommande(commandeId));
    } catch (error) {
      dispatch({ type: "CART_ERROR" });
    }
  };

export const validateCommande =
  (setShowDialog, commande) => async (dispatch) => {
    dispatch({ type: "CART_LOADING", payload: commande.id });

    try {
      console.log({ commande });
      console.log({ bon: commande?.bon_de_commande });
      // if (!commande.bon_de_commande) {
      await dispatch(generateBonDeCommande(commande.id));
      // }
      await NEW_API.post(`/api/validateCommande`, {
        data: { commandeId: commande.id },
      });
      dispatch({ type: "CART_VALIDATED" });
      setShowDialog(true);
    } catch (error) {
      dispatch({ type: "CART_ERROR" });
    }
  };
