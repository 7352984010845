import React, { useEffect, useState } from "react";

import { approvColumnsList } from "../columnsList";
import ApprovCard from "./ApprovCard";
import { useDispatch, useSelector } from "react-redux";
import { getApprovs } from "../../../REDUX/actions/approvisionnement";
import LoaderWrapper from "../../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import { useInView } from "react-intersection-observer";
import { Spinner } from "react-bootstrap";

const ApprovViewContent = ({ searchInput, filtersInput }) => {
  const dispatch = useDispatch();
  const { approvs, approvsLoading, approvsError, approvsRefetchLoading } =
    useSelector((state) => state.approvReducer);
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  const [page, setpage] = useState(1);

  useEffect(() => {
    if (inView && !approvsRefetchLoading) {
      setpage(page + 1);
      dispatch(getApprovs({ page }));
    }
  }, [inView]);

  // console.log(approvs?.length);
  // console.log({ page });

  return (
    <div className="flex-1 d-flex flex-column">
      <div className="bg-dimGrey py-3 px-4 mx-0 w-100 alignH">
        {approvColumnsList?.map(({ title, className }, index) => (
          <span
            key={index}
            className={`${className} text-lightBlack sBold f14`}
          >
            {title}
          </span>
        ))}
      </div>
      <div className="flex-1">
        <LoaderWrapper loading={approvsLoading} error={approvsError}>
          {approvs?.map((approv) => (
            <div key={approv?.id}>
              <ApprovCard {...approv} />
              <hr
                style={{ borderColor: "#abb8b2", margin: 0 }}
                width={"100%"}
              />
            </div>
          ))}
        </LoaderWrapper>
      </div>
      <div className="alignC" ref={ref}>
        {approvsRefetchLoading ? <Spinner /> : null}
      </div>
    </div>
  );
};

export default ApprovViewContent;
