import React, { useState } from "react";
import VerticalPoints from "../../Common/VerticalPoints/VerticalPoints";
import ApprovDetailsList from "./ApprovDetails/ApprovDetailsList";
import { useDispatch } from "react-redux";
import { openCenteredModal } from "../../../REDUX/actions/centeredModal";
import DeleteApprov from "./DeleteApprov";
import { openRightModal } from "../../../REDUX/actions/rightModal";
import ModifyContainer from "./ModifyContainer";
import {
  formatDate,
  getFileName,
  handleDownload,
} from "../../../Utils/fileUtils";
import { saveAs } from "file-saver";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";
import adjustPrice from "../../../Utils/AdjustPrice";
import CustomTooltip from "../../Common/CustomTooltip/CustomTooltip";

const ApprovCard = ({
  id,
  containerName,
  factory,
  orderDate,
  estimatedDate,
  arrivalDate,
  taux,
  QC,
  deliveryPrice,
  returnCost,
  packingList,
  attachments,
  entrepot,
  realEntrepot,
  page,
}) => {
  const dispatch = useDispatch();
  const [showApprovDetails, setShowApprovDetails] = useState(false);

  const toggleShowApprovDetails = () => {
    setShowApprovDetails(!showApprovDetails);
  };

  const handleDelete = () => {
    dispatch(
      openCenteredModal(
        <DeleteApprov id={id} packingList={packingList} page={page} />
      )
    );
  };

  const downloadFiles = () => {
    if (!attachments?.length > 0) return [];
    if (
      attachments?.length === 1 &&
      attachments[0]?.attributes?.file?.data?.attributes?.url
    ) {
      saveAs(
        IMAGE_END_POINT +
          attachments[0]?.attributes?.file?.data?.attributes?.url,
        getFileName(attachments[0]?.attri8butes?.file?.data?.attributes?.url)
      );
      return;
    }
    const list = attachments
      ?.map((item) => ({
        fileUrl: item?.attributes?.file?.data?.attributes?.url,
        fileName: item?.attributes?.file?.data?.attributes?.name,
      }))
      ?.filter((item) => !!item?.fileUrl);
    handleDownload(list);
  };

  const bgColor = showApprovDetails ? "bg-blue" : "";
  const textColor = showApprovDetails ? "text-white" : "text-brightGrey";
  const showClassName = showApprovDetails ? "show" : "";

  return (
    <div>
      <div
        className={`p-4 alignH w-100 orderCardContainer pointer ${bgColor} ${showClassName}`}
        onClick={toggleShowApprovDetails}
      >
        <div className={` w10 d-flex align-items-center gap-2`}>
          <span className={`f14 regular ${textColor}`}>
            {containerName ?? "-"}
          </span>
          {entrepot && (
            <CustomTooltip
              title={
                <>
                  <span>Entrepôt </span>
                  <span className="medium">{entrepot}</span>
                </>
              }
            >
              <img src="entrepot.png" alt="entrepo" width={"20px"} />
            </CustomTooltip>
          )}
        </div>

        <span className={`f14 normal w10 ${textColor}`}>{factory ?? "-"}</span>
        <span className={`f14 normal w13 ${textColor}`}>
          {orderDate ? formatDate(orderDate, "DD-MM-YYYY") : "-"}
        </span>
        <span className={`f14 normal w13 ${textColor}`}>
          {estimatedDate ? formatDate(estimatedDate, "DD-MM-YYYY") : "-"}
        </span>
        <span className={`f14 normal w10 ${textColor}`}>
          {arrivalDate ? formatDate(arrivalDate, "DD-MM-YYYY") : "-"}
        </span>
        <span className={`f14 normal w10 ${textColor}`}>{taux || "-"}</span>
        <span className={`f14 normal w10 ${textColor} alignH`}>
          {/* {QC ? `${QC}` : 0} */}
          {QC ===
          packingList?.reduce((acc, curr) => {
            return acc + parseFloat(curr?.qc || 0);
          }, 0)
            ? `${QC}`
            : packingList?.reduce((acc, curr) => {
                return acc + parseFloat(curr?.qc || 0);
              }, 0) ?? 0}
        </span>
        <span className={`f14 normal w10 ${textColor} alignH`}>
          {`${adjustPrice((deliveryPrice ?? 0).toFixed(2).toString())} €` ||
            "-"}
        </span>
        <span className={`f14 normal w10 ${textColor} alignH textNoWrap`}>
          {returnCost
            ? `${adjustPrice((returnCost ?? 0).toFixed(2).toString())} €`
            : "-"}
        </span>
        <span className={`f14 normal w4 ${textColor} alignH textNoWrap`}>
          <VerticalPoints
            showLeft
            options={[
              {
                text: "Modifier le container",
                callback: () =>
                  dispatch(
                    openRightModal(
                      <ModifyContainer id={id} name={containerName} />
                    )
                  ),
              },

              {
                text: "Télécharger la facture",
                callback: () => downloadFiles(),
              },
              // { text: "Télécharger le packing list", downloadUrl: downloadUrl },
              { text: "Ajouter un commentaire" },
              {
                text: "Supprimer le container",
                callback: handleDelete,
                red: true,
              },
            ]}
            className="ms-auto"
          />
        </span>
      </div>
      {showApprovDetails ? (
        <ApprovDetailsList packingList={packingList} />
      ) : null}
    </div>
  );
};

export default ApprovCard;
