import React, { useEffect, useState } from "react";
import VerticalPoints from "../Common/VerticalPoints/VerticalPoints";
import ProductImage from "../../Images/Images/Commandes/product.png";
import { getProductType, getStatusStyle } from "../../Utils";
import { openRightModal } from "../../REDUX/actions/rightModal";
import UpdateProductAdmin from "./UpdateProductAdmin";
import { useDispatch } from "react-redux";
import { deleteProduct, duplicateProduct } from "../../REDUX/actions/products";
import { openCenteredModal } from "../../REDUX/actions/centeredModal";
import ConfirmModal from "../Common/Modal/ConfirmModal";
import { IMAGE_END_POINT } from "../../Api/EndPoints";
import adjustPrice from "../../Utils/AdjustPrice";
import defaultImage from "../../Images/Images/default-image.webp";

const ProductCardAdmin = ({
  id,
  name,
  category,
  gamme,
  retailerPriceHT,
  productType,
  publicPriceTTC,
  buyingPrice,
  status,
  equipement,
  light_sensor_serie,
  caracteristique,
  attachments,
}) => {
  const dispatch = useDispatch();
  const [statusStyle, setStatusStyle] = useState();

  useEffect(() => {
    setStatusStyle(getStatusStyle(status));
  }, [status]);

  const image = attachments?.data?.find((item) => {
    return item?.attributes?.file?.data?.attributes?.mime.includes("image");
  })?.attributes?.file?.data?.attributes?.url;

  const txMargePO = retailerPriceHT
    ? ((retailerPriceHT - buyingPrice) / retailerPriceHT) * 100
    : 0;

  const txMargeRev = publicPriceTTC
    ? ((publicPriceTTC / 1.2 - retailerPriceHT) / (publicPriceTTC / 1.2)) * 100
    : 0;

  return (
    <div
      onClick={() => dispatch(openRightModal(<UpdateProductAdmin id={id} />))}
    >
      <div
        className={`row ps-4 py-3 d-flex justify-content-between align-items-center w-100 orderCardContainer `}
      >
        <span className={` f14 normal col-1 overflow-hidden`}>
          <img
            src={image ? `${IMAGE_END_POINT}${image}` : defaultImage}
            alt="product"
            width={45}
            className={`${image ? "" : "object-cover scale-3-50"}`}
          />
        </span>
        <span className={` f14 normal col-2 text-customBlack `}>{name}</span>
        <span className={` f14 normal col-1 text-lightBlack `}>
          {category?.data?.attributes?.name ??
            getProductType(productType)?.toUpperCase()}
        </span>
        <span className={` f14 normal col-1 text-lightBlack `}>
          {gamme?.data?.attributes?.name ?? "-"}
        </span>
        <span className={` f14 normal col-1 text-lightBlack `}>{`${adjustPrice(
          (buyingPrice ?? 0).toFixed(2).toString()
        )} €`}</span>
        <span className={` f14 normal col-1 text-lightBlack `}>{`${adjustPrice(
          (txMargePO ?? 0).toFixed(2).toString()
        )} %`}</span>
        <span className={` f14 normal col-1 text-lightBlack `}>{`${adjustPrice(
          (retailerPriceHT ?? 0).toFixed(2).toString()
        )} €`}</span>
        <span className={` f14 normal col-1 text-lightBlack `}>{`${adjustPrice(
          (txMargeRev ?? 0).toFixed(2).toString()
        )} %`}</span>
        <span className={` f14 normal col-1 text-lightBlack `}>{`${adjustPrice(
          (publicPriceTTC ?? 0).toFixed(2).toString()
        )} €`}</span>
        <span className={` f12 normal col-1 `}>
          <span
            className={`${statusStyle?.statusTextStyle} ${statusStyle?.statusBgStyle} text-center px-3 col-1 br8 py-2`}
          >
            {status}
          </span>
        </span>

        <span className={` f14 normal col-1`}>
          <VerticalPoints
            showLeft
            options={[
              {
                text: "Modifier",
                callback: () =>
                  dispatch(openRightModal(<UpdateProductAdmin id={id} />)),
              },
              {
                text: "Dupliquer",
                callback: () => {
                  dispatch(duplicateProduct(id));
                  console.log("DUPLICATE !!!!!");
                },
              },
              {
                text: "Supprimer",
                red: true,
                callback: () =>
                  dispatch(
                    openCenteredModal(
                      <ConfirmModal
                        label="le produit"
                        id={id}
                        onConfirm={() =>
                          dispatch(
                            deleteProduct(
                              id,
                              equipement?.data?.id,
                              light_sensor_serie?.data?.id,
                              caracteristique?.data?.id,
                              attachments?.data
                            )
                          )
                        }
                      />
                    )
                  ),
              },
            ]}
          />
        </span>
      </div>
    </div>
  );
};

export default ProductCardAdmin;
